<template>
  <div class="download-box">
    <div class="download-box-top u-text-center">
      <img class="download-top-img" src="../../assets/images/logo.png" />
      <p class="download-top-tips">正规持牌机构，应你之急</p>

      <!-- 打开APP完成借款 -->
      <div class="pr">
        <van-button class="btn" type="default" @click="clickDownload">
          打开APP完成借款
        </van-button>
        <small class="pa btn-tips">最高可获得20万借款额度</small>
      </div>
    </div>

    <!-- 红包 优惠券展示区域 -->
    <div class="coupons-con u-text-center">
      <p>您还有以下APP专享福利待领取</p>
      <b class="line" />
      <div class="u-flex u-row-center">
        <div class="coupons-con-left u-flex-col u-row-between">
          <div>
            <h4 class="u-flex u-row-center">
              <img src="../../assets/images/logo.png" />
              <span>发出的现金红包</span>
            </h4>
            <h1 class="u-flex u-row-center">
              <span>￥</span>
              <p>8.80</p>
            </h1>
            <h5>借款秒审批，最高20万</h5>
          </div>
        </div>
        <div class="coupons-con-right">
          <p>可在APP内取款时抵扣相应利息</p>
          <div class="u-flex u-row-between coupon-item">
            <div>
              <h5>30<small>天</small></h5>
              <p>借款免息券</p>
            </div>
            <span>已领取</span>
          </div>
          <div class="u-flex u-row-between coupon-item">
            <div>
              <h5>30<small>天</small></h5>
              <p>借款免息券</p>
            </div>
            <span>已领取</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OpenApp",
  data() {
    return {
      client: this.MixinClientBrowser(), // 设备
      channel: this.$route.query.source, // 渠道标识
      brand: this.$route.query.brand, // 设备型号
    };
  },
  mounted() {
    this.clickDownload();
  },
  methods: {
    // 检测手机是否安装APP
    clickDownload() {
      if (this.client === "ios") {
        var loadDateTime = new Date();
        window.location = "youcaiIos://YCLoan.push.YCDiscoverViewController";
        window.setTimeout(function () {
          //如果没有安装app,便会执行setTimeout跳转下载页
          var timeOutDateTime = new Date();
          if (timeOutDateTime - loadDateTime < 5000) {
            window.location = "/downloadApp"; // 跳转下载页面
          } else {
            window.close();
          }
        }, 500);
      } else if (this.client === "android") {
        try {
          window.location =
            "youcaiandroid://com.pxl.youcailoan/h5jump?pageName=LoanFragment";
          window.setTimeout(function () {
            window.location = "/downloadApp"; // 跳转下载页面
          }, 2500);
        } catch (e) {
          console.log(e);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.download-box {
  height: 100vh;
  background: #ffffff;
  font-size: 0.24rem;
  overflow: hidden;

  .download-box-top {
    width: 100%;
    height: 6.92rem;
    padding: 1.2rem 1.36rem;
    background: url("../../assets/images/download_app_bg.png") no-repeat center
      center;
    background-size: 100% 100%;

    .download-top-img {
      width: 1.68rem;
      height: 1.68rem;
    }

    .download-top-tips {
      font-size: 0.28rem;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: #2d3546;
      margin: 0.3rem 0 1.06rem;
    }

    .btn {
      width: 100%;
      height: 0.96rem;
      background: #3563fa;
      border-radius: 0.16rem;
      font-size: 0.32rem;
      font-weight: 500;
      color: #ffffff;
      line-height: 0.96rem;
      border: none;
    }

    .btn-tips {
      width: 3.1rem;
      height: 0.35rem;
      background: #f03d23;
      border: 0.02rem solid #ffffff;
      border-radius: 2rem 2rem 2rem 0;
      padding: 0 0.16rem;
      color: #fff;
      line-height: 0.35rem;
      font-size: 0.22rem;
      left: 50%;
      bottom: 85%;
    }
  }

  .coupons-con {
    padding: 0.48rem 0.4rem;

    > p {
      font-size: 0.28rem;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: #252d3f;
    }

    > b {
      width: 0.48rem;
      height: 0.08rem;
      background: #3563fa;
      border-radius: 0.16rem;
      margin: 0.12rem 0 0.36rem;
      display: inline-block;
    }

    .coupons-con-left,
    .coupons-con-right {
      width: 3.9rem;
      height: 3.74rem;
    }

    .coupons-con-left {
      background: url("../../assets/images/red_envelopes_bg.png") no-repeat
        center;
      background-size: 100% 100%;
      padding: 0.38rem 0 0.36rem;
      margin-right: 0.3rem;

      h4 {
        font-size: 0.22rem;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: #3c3c34;

        > img {
          width: 0.36rem;
          height: 0.36rem;
          border-radius: 50%;
          margin-right: 0.08rem;
        }

        > span {
          font-size: 0.22rem;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          color: rgba(255, 242, 206, 0.5);
        }
      }

      h1 {
        font-size: 0.72rem;
        font-family: AlibabaPuHuiTiB, AlibabaPuHuiTiB-Regular;
        font-weight: 400;
        color: #fff2ce;

        > span {
          font-size: 0.32rem;
          margin-top: 0.3rem;
        }
      }

      h5 {
        font-size: 0.26rem;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: #fff2ce;
      }
    }

    .coupons-con-right {
      margin-right: 0;
      background: #fff6ee;
      border: 0.02rem solid #fde6e3;
      border-radius: 0.18rem;
      padding: 0.24rem 0.16rem;

      > p {
        font-size: 0.22rem;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #c0433a;
        margin-bottom: 0.15rem;

        + div {
          margin-bottom: 0.08rem;
        }
      }

      > div {
        height: 1.18rem;
        background: url("../../assets/images/passedSuccess_tl4_coupon.png")
          no-repeat center;
        background-size: 100% 100%;

        &.coupon-item {
          padding: 0 0.22rem 0 0.48rem;
          font-size: 0.24rem;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          color: rgba(240, 61, 35, 0.5);

          h5 {
            font-size: 0.5rem;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: 500;
            color: #f03d23;
            margin-bottom: -0.1rem;
            > small {
              font-size: 0.24rem;
            }
          }

          span {
            color: #f03d23;
            writing-mode: tb-rl;
          }
        }
      }
    }
  }
}
</style>
